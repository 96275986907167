import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { Form, Input, Button, notification, Modal, message } from 'antd';
import { BASE_URL, parseWarningsAndErrors } from "../conf/config";
import './GenerateFilePopup.css';
import { checkListOfDictSanity } from "../utils/utils";
import { useUser } from "../UserContext";

const AddOrderPop = forwardRef((props, ref) => {

    // define ref to forward it to the parent component
    const dialogRef = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    // submit data as form
    const [form] = Form.useForm();
    // imported orders from the textArea
    const [invoiceOrders, setInvoiceOrders] = useState([]);

    const { refreshOrders, addNewLog, fetchWithAuth } = useUser();

    const requiredFields = ["position_ID", "order_ID", "CPN", "shipping_date_planned", "quantity", "order_type", "customer"];

    notification.config({
        top: 50,
        zIndex: 10000, // make sure it will be on top of dialog
    });

    useImperativeHandle(ref, () => ({
        open: () => {
            setIsModalOpen(true);
        },
        setInvoiceOrders: (orders) => {
            setInvoiceOrders(orders);
            form.setFieldsValue({ orders });
        },
    }));

    useEffect(() => {
        console.log("invoiceOrders", invoiceOrders);
    }, [invoiceOrders]);

    const onFinish = (values) => {
        const checkSanity = checkListOfDictSanity(values.orders);
        if (checkSanity !== "sane") {
            notification.warning({ message: 'Warning', description: checkSanity });
            return;
        }

        const msg = { "type": "add", "data": values.orders };
        fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify(msg),
        })
            .then(res => res.json())
            .then(data => {
                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                addNewLog(`add orders`, parsedWarnings, parsedErrors, msg);

                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors });
                    return;
                }

                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings });
                }

                if (data["detail"]) {
                    notification.error({ message: 'Error', description: data["detail"] });
                    return;
                }

                refreshOrders();
                setIsModalOpen(false);
            })
            .catch(error => {
                notification.error({ message: 'Error', description: 'Error generating file: ' + error.message });
            });
    };

    const closeDialog = () => {
        setIsModalOpen(false);
    };

    // render the fields of the form
    const renderField = (name, field, isRequired = false) => (
        <Form.Item
            name={[name, field]}
            key={field}
            rules={isRequired ? [{ required: true, message: `${field} is required` }] : []}
        >
            <Input style={{ width: 'auto', minWidth: '20px' }} />
        </Form.Item>
    );


    return (
        <Modal className="InvoicePopUp-container"
            open={isModalOpen} ref={dialogRef}
            footer={null} title={null}
            closable={false}
            style={{
                top: '7vh',
            }}
            width={"80vw"}>
            <div className="dialog-header">
                <Button type="primary" onClick={closeDialog} className="close-button" danger>Close</Button>
            </div>
            <Form form={form} onFinish={onFinish} layout="vertical" className="popup-form-container">
                <Form.Item className="edit-button-group">
                    <Button className="edit-button" type="primary" htmlType="submit">
                        Add orders
                    </Button>
                </Form.Item>
                <Form.List name="orders">
                    {/* render the fields of the form */}
                    {(fields) => (
                        <div className="orders-table-container">
                            <table className="orders-table">
                                <thead>
                                    <tr>
                                        {fields.length > 0 && (
                                            <>
                                                {requiredFields.concat(Object.keys(invoiceOrders[0] || {}).filter(field => !requiredFields.includes(field))).map((field) => (
                                                    <th key={field} className="table-header-cell">
                                                        {requiredFields.includes(field) ? (
                                                            <span>
                                                                {field} <span style={{ color: 'red' }}>*</span>
                                                            </span>
                                                        ) : (
                                                            field
                                                        )}
                                                    </th>
                                                ))}
                                            </>
                                        )}
                                    </tr>
                                </thead>

                                <tbody>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <tr key={key} className="table-row">
                                            {requiredFields.concat(Object.keys(invoiceOrders[name] || {}).filter(field => !requiredFields.includes(field))).map((field) => (
                                                <td key={field} className="table-cell">
                                                    {renderField(name, field, requiredFields.includes(field))}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
});

export default AddOrderPop;
