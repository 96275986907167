import React, { useEffect, useContext } from "react";
import { BASE_URL, parseWarningsAndErrors } from "../conf/config";
import { Button, Form, Input, Select, Table, notification } from 'antd';
import { PushpinOutlined, PushpinFilled, DeleteOutlined, DeleteFilled } from '@ant-design/icons';
import GenerateFilePopup from "../components/GenerateFilePopup";
import { useUser } from "../UserContext";
import { checkDictOfListSanity } from "../utils/utils";
import "./Orders.css";
import { openPDFInNewTab } from "../components/GenerateFilePopup";
import { parseDate } from "../conf/config";
import { getCookie } from "../conf/config";
import { OrderContext } from "../OrdersContext";



const OrdersPage = () => {
    // Retrieve user and order data, along with utility functions
    const { orders, setOrders, refreshOrders, addNewLog, fetchWithAuth } = useUser();

    const {
        pinOrders, setPinOrders, searchedResult, setSearchedResult, cols, setCols,
        options, selectedRowKeys, setSelectedRowKeys,
        searchFields, setSearchFields, colFields, setColFields,
        current, setCurrent, pageSize, setPageSize, dropDownSearchItem, setDropDownSearchItem,
        statusOptions, handlePinClick, getSorter,
        GenerateFileRef,
        fieldMapping
    } = useContext(OrderContext);
    // search fields
    const [form] = Form.useForm();


    // default values shows on the search fields and table columns headers.
    const defaultSearchItem = ["id", "status", "customer", "order_type", "order_ID"];
    const defaultColumnFields = ["id", "status", "customer", "order_type", "order_ID", "position_ID", "CPN", "shipping_date_planned", "order_date", "shipping_ID", "quantity", "fob_point", "incoterm"];


    // Order table selector
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // variables which need for antd table
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                // if select all clicked. choose all orders
                const allOrderIds = orders.map(order => order.id);
                console.log(allOrderIds);
                setSelectedRowKeys(allOrderIds);
            } else {
                setSelectedRowKeys([]);
            }
        }
    };


    const hasSelected = selectedRowKeys.length > 0;

    // used to order select options
    function sortColFieldsAlphabetically(colFields) {
        const newColFields = [...colFields];

        newColFields.sort((a, b) => {
            let strA = String(a.label).toLowerCase();
            let strB = String(b.label).toLowerCase();

            return strA.localeCompare(strB);
        });

        return newColFields;
    }

    // Delete one column from the table
    const handleColFieldDelete = (field) => {
        const newColFields = colFields.filter(item => item.value !== field.value);
        setColFields(newColFields);
    }



    useEffect(() => {
        // if already read orders message from backend
        if (colFields.length > 0) {
            // add pin icon to the header
            const cols = [
                {
                    title: '',
                    render: (_, record) => (
                        pinOrders.some(order => order.id === record.id) ? (
                            <PushpinFilled onClick={() => handlePinClick(record)} />
                        ) : (
                            <PushpinOutlined onClick={() => handlePinClick(record)} />
                        )
                    ),
                    align: 'center',
                },
                ...colFields.map((field, index) => ({
                    title: () => (
                        <div className="table-header-column custom-header">

                            <Select
                                // options={getFilteredColItems()}
                                className="custom-selector"
                                dropdownStyle={{ width: 150 }}
                                options={sortColFieldsAlphabetically(options)}
                                value={fieldMapping[field.value] || field.value}
                                variant={false}
                                onClick={(e) => e.stopPropagation()}  // prevent it trigger the table row click event
                                onChange={(value) => handleColFieldChange(value, index)}
                            />
                            <div
                                className="delete-icon-wrapper"
                                onClick={() => handleColFieldDelete(field)}
                            >
                                <DeleteOutlined className="delete-icon" />
                                <DeleteFilled className="delete-icon filled" />
                            </div>
                        </div>
                    ),
                    dataIndex: field.value,
                    key: field.value,
                    sorter: (a, b, sortOrder) => getSorter(field.value)(a, b, sortOrder),
                    sortDirections: ['ascend', 'descend'],
                    render: (text) => (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {text}
                        </div>
                    ),
                    align: 'center',
                })).filter(col => col.dataIndex)
            ];
            setCols(cols);
        }
    }, [colFields, pinOrders]);


    const addSearchField = () => {
        setSearchFields([...searchFields, getFilteredDropdownItems()[0]]);
    };

    // add field to table headers
    const addColField = () => { setColFields([...colFields, getFilteredColItems()[0]]); };

    // modify the search form if one of the header changes
    const handleFieldChange = (value, index) => {
        const newSearchFields = [...searchFields];
        newSearchFields[index] = { ...newSearchFields[index], value, label: value };
        // console.log("newSearchFields", newSearchFields);
        setSearchFields(newSearchFields);
    };

    const handleColFieldChange = (value, index) => {
        const newColFields = [...colFields];
        newColFields[index] = { ...newColFields[index], value, label: value };
        console.log("value and index", value, index);
        console.log("newColFields", newColFields);
        setColFields(newColFields);

    }

    // Get filtered dropdown items for fields
    const getFilteredDropdownItems = () => {
        return dropDownSearchItem.filter(item => !searchFields.some(field => field.label === item.label));

    };

    const getFilteredColItems = () => {
        return dropDownSearchItem.filter(item => !colFields.some(field => field.label === item.label));
    };

    // Filters columns based on values entered in the form
    const filteredColumns = (values) => {
        const filteredValues = Object.keys(values).reduce((acc, key) => {
            if (values[key] !== undefined && values[key] !== null && values[key] !== '') {
                const value_list = values[key].trim().split(";")
                    .map(value => value.trim())
                    .filter(value => value !== "");
                acc[key] = value_list;
            }
            return acc;
        }, {});

        return filteredValues;
    }

    // Search orders based on form values
    const onOrderSearch = (values) => {

        const filteredValues = filteredColumns(values);
        const checkSanity = checkDictOfListSanity(filteredValues);
        if (checkSanity !== "sane") {
            notification.warning({ message: 'warning', description: checkSanity })
            return;
        }
        const msg = { "type": "search", "data": filteredValues };

        fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            body: JSON.stringify(msg)
        }).then((res) => {
            if (res.status === 401) {
                // unauthorized
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                notification.error({ message: 'Error', description: 'Unauthorized, Please login' })
                window.location.href = '/login';
                return;
            }
            return res.json();
        })

            .then((data) => {
                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                addNewLog("Order search", parsedWarnings, parsedErrors, msg);
                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors });
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings });
                }
                setSearchedResult(data)
            }).catch(err => console.log(err))
    }

    // handle four types of file generation
    const onFileGenerateClick = (type) => {
        const currentDate = new Date();
        // fields that put to the front columns of the table
        const keyFields = ['customer', 'order_ID', 'position_ID', 'CPN', 'quantity', 'shipping_ID', 'tracking_ID', 'total_net', 'unit_price'];
        if (type === 'invoice') {
            form.setFieldsValue({ status: 'shipped' });
            form.submit();
            setColFields(newColFields);
        } else if (type === 'overdueOrders') {
            const overdueOrders = orders.filter((order) => {
                const shippingDatePlanned = parseDate(order.shipping_date_planned);
                return (
                    shippingDatePlanned < currentDate &&
                    order.status === "stwxe"
                );
            });
            setSearchedResult(overdueOrders);
        } else if (type === 'overduePayments') {
            const overduePayments = orders.filter((order) => {
                const invoiceDate = parseDate(order.invoice_date);
                return (
                    invoiceDate < currentDate &&
                    order.status !== "paid"
                );
            });
            setSearchedResult(overduePayments);
        } else if (type === 'upcomingShipments') {
            const upcomingOrders = orders.filter((order) => {
                const shippingDatePlanned = parseDate(order.shipping_date_planned);
                const daysDifference = (shippingDatePlanned - currentDate) / (1000 * 60 * 60 * 24);

                return daysDifference >= 0 && daysDifference <= 7;
            }).sort((a, b) => parseDate(a.shipping_date_planned) - parseDate(b.shipping_date_planned));

            setSearchedResult(upcomingOrders);
        }

        const newColFields = keyFields.map(field => ({
            label: fieldMapping[field] || field,
            value: field
        }));
    };


    // open modal dealing with file generation
    const onGenereteFile = (type) => {

        const invoiceOrders = orders.filter(order => selectedRowKeys.includes(order.id))
        GenerateFileRef.current.setInvoiceOrders(invoiceOrders);
        GenerateFileRef.current.setFileType(type);
        GenerateFileRef.current.open(selectedRowKeys);
    }

    const exportCSV = () => {
        const accessKey = getCookie("access_token");

        const exportOrders = orders.filter(order => selectedRowKeys.includes(order.id));
        const msg = { "type": "export", "data": exportOrders };

        fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessKey}`
            },
            body: JSON.stringify(msg)
        }).then((res) => res.json())
            .then(async (data) => {
                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                addNewLog("export to csv", parsedWarnings, parsedErrors, msg);
                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors });
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings });
                }

                console.log("export to csv data", data);


                // if urls is an Array
                if (Array.isArray(data["urls"])) {
                    for (let url of data["urls"]) {
                        try {
                            const response = await fetch(url, {
                                method: 'GET',
                                headers: {
                                    'Authorization': `Bearer ${accessKey}`
                                }
                            });

                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }

                            // tranform to blob
                            const blob = await response.blob();
                            const downloadUrl = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = downloadUrl;
                            const filename = url.split("/").pop();
                            link.download = filename;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(downloadUrl);

                        } catch (error) {
                            console.error('Error downloading file:', error);
                            notification.error({ message: 'Error', description: 'Failed to download file from ' + url });
                        }
                    }
                }
                // if not, only download one file
                else {
                    try {
                        const response = await fetch(data["urls"], {
                            method: 'GET',
                            headers: {
                                'Authorization': `Bearer ${accessKey}`
                            }
                        });

                        if (!response.ok)
                            throw new Error('Network response was not ok');

                        const blob = await response.blob();
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        const filename = data["urls"].split("/").pop();
                        link.download = filename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(downloadUrl);

                    } catch (error) {
                        console.error('Error downloading file:', error);
                        notification.error({ message: 'Error', description: 'Failed to download file from ' + data["urls"] });
                    }

                }


            }).catch((error) => {
                console.error('Error:', error);
                notification.error({ message: 'Error', description: 'Failed to export orders' });
            });
    };


    // reset all the perseved state obtain from the context
    const resetSearch = () => {
        form.resetFields();
        const firstRecord = orders[0];
        if (typeof firstRecord !== 'object') {
            throw new TypeError('not support response type');
        } else {

            const searchItems = Object.keys(firstRecord).map((key, index) => ({
                label: key,
                key: index,
                value: key
            }));

            setDropDownSearchItem(searchItems);
            setSearchFields(defaultSearchItem.map(item => ({
                label: item,
                value: item
            })));
            setSearchedResult([]);

        }
    }


    const onMarkAsPaid = () => {
        const markedOrders = orders.filter(order => selectedRowKeys.includes(order.id));
        const msg = { "type": "payment", "data": markedOrders };
        fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            body: JSON.stringify(msg)
        })
            .then(res => res.json())
            .then(data => {
                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                addNewLog("Mark orders as paid", parsedWarnings, parsedErrors, msg);
                const updatedList = data["DB"];

                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors })
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings })
                }

                // update the orders which has the same id in updated list
                setOrders(orders.map(order => updatedList.find(updated => updated.id === order.id) || order));
                refreshOrders();
                notification.success({ message: 'Success', description: 'Marked as paid successfully.' });


                if (data["detail"]) {
                    notification.error({ message: 'Error', description: data["detail"] });
                    return;
                }
            })
            .catch(error => console.error('Error:', error));

    };


    // simply send a test request 
    const onTest = () => {
        const markedOrders = orders.filter(order => selectedRowKeys.includes(order.id));
        const msg = { "type": "test", "data": markedOrders };
        fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            body: JSON.stringify(msg)
        })
            .then(res => res.json())
            .then(data => {

                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                addNewLog("Cancel invoices", parsedWarnings, parsedErrors, msg);

                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors })
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings })
                }

                const updatedList = data["DB"];
                // update the orders which has the same id in updated list
                setOrders(orders.map(order => updatedList.find(updated => updated.id === order.id) || order));
                openPDFInNewTab(data);
                refreshOrders();
                notification.success({ message: 'Success', description: 'Invoices cancelled successfully.' });


                if (data["detail"]) {
                    notification.error({ message: 'Error', description: data["detail"] });
                    return;
                }
            })
            .catch(error => console.error('Error:', error));

    };


    //input for antd table
    const TableDataSource = () => {
        let dataSource = [];
        const data = Array.isArray(searchedResult) && searchedResult.length > 0 ? searchedResult : orders;
        const pinOrdersArray = Array.isArray(pinOrders) ? pinOrders : [];

        // filter out those orders already exist in pinOrders
        const filteredData = data.filter(order => !pinOrdersArray.some(pinned => pinned.id === order.id));
        dataSource = [...pinOrdersArray, ...filteredData];
        return dataSource;
    }


    return (
        <div>
            <div className="form-container">
                {/* all search fields are structured in the Form label */}
                <Form form={form} onFinish={onOrderSearch} layout="horizontal" style={{ maxWidth: 'none' }}>
                    <div className="search-form">
                        <Button className="search-button-group" type="primary" htmlType="submit">Search</Button>
                        <Button className="search-button-group" type="primary" onClick={resetSearch} danger>reset</Button>

                        <div className="search-field-group">
                            {searchFields.map((field, index) => (
                                <div key={index} className="search-field-container">
                                    <div className="search-form-item">
                                        <Select
                                            options={sortColFieldsAlphabetically(getFilteredDropdownItems())}
                                            onChange={(value) => handleFieldChange(value, index)}
                                            value={fieldMapping[field.value] || field.value}
                                            className="search-select custom-dropdown"
                                            dropdownStyle={{ minWidth: '100px' }}
                                            placeholder="Select Field"
                                        />

                                        {field.value === 'status' ? (
                                            //  if the field is status, directly show a select
                                            <Form.Item name={`${field.value}`} noStyle style={{ width: "100%" }}>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    options={statusOptions}
                                                    className="search-select custom-dropdown"
                                                    placeholder="Select Status"
                                                />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item name={`${field.value}`} noStyle>
                                                <Input className="search-input" />
                                            </Form.Item>
                                        )}
                                    </div>
                                </div>
                            ))}

                        </div>
                        <Button className="search-button-group" type="dashed" onClick={addSearchField}>Add Field</Button>
                    </div>
                </Form>
            </div>

            {/* quick search options */}
            <div className="boomer-buttons">
                <div>Quick Query:</div>
                <Button type="primary" onClick={() => onFileGenerateClick('invoice')}>
                    Invoices
                </Button>
                <Button type="primary" onClick={() => onFileGenerateClick('overdueOrders')}>
                    Overdue Orders
                </Button>
                <Button type="primary" onClick={() => onFileGenerateClick('overduePayments')}>
                    Overdue Payments
                </Button>
                <Button type="primary" onClick={() => onFileGenerateClick('upcomingShipments')}>
                    Upcoming shipments
                </Button>
            </div>


            <div className="main-container">
                <div className="table-control-buttons">
                    <Button onClick={addColField} type="primary" >Add Table Column</Button>
                </div>
                <div className="table-content">
                    <div className="generate-file-buttons">
                        <Button onClick={() => onGenereteFile("edit")} type="primary" style={{ backgroundColor: 'DarkSeaGreen ' }} disabled={!hasSelected}>
                            Edit
                        </Button>

                        <Button onClick={() => onGenereteFile("invoice")} type="primary" disabled={!hasSelected}>
                            Invoice
                        </Button>
                        <Button onClick={() => onGenereteFile("stwxe_order")} type="primary" disabled={!hasSelected}>
                            Stwxe
                        </Button>
                        <Button onClick={() => onGenereteFile("confirmation")} type="primary" disabled={!hasSelected}>
                            Confirmation
                        </Button>
                        <Button onClick={() => onGenereteFile("combine_invoice")} type="primary" disabled={!hasSelected}>
                            Combine invoice
                        </Button>
                        <Button onClick={() => onGenereteFile("combine_stwxe")} type="primary" disabled={!hasSelected}>
                            Combine stwxe
                        </Button>
                        <Button onClick={() => onGenereteFile("combine_confirmation")} type="primary" disabled={!hasSelected}>
                            Combine confirmation
                        </Button>
                        <Button onClick={() => onGenereteFile("china_import")} type="primary" disabled={!hasSelected}>
                            Import China
                        </Button>
                        <Button onClick={exportCSV} type="primary" disabled={!hasSelected}>
                            Export to CSV
                        </Button>
                        <Button type="primary" style={{ backgroundColor: 'Coral' }} onClick={onMarkAsPaid} disabled={!hasSelected}>
                            Mark as paid
                        </Button>
                        {/* <Button type="primary" danger onClick={onCancelInvoices} disabled={!hasSelected}> */}
                        <Button type="primary" danger onClick={() => onGenereteFile("invoice_cancel")} disabled={!hasSelected}>
                            Cancel invoices
                        </Button>
                        <Button type="primary" style={{ backgroundColor: 'silver' }} onClick={onTest} disabled={!hasSelected}>
                            Test
                        </Button>
                        {/* add button here if you want some extra funtion button */}
                    </div>
                    <div className="table-container">
                        <Table
                            className="order-table"
                            rowSelection={rowSelection}
                            dataSource={TableDataSource()}
                            scroll={{ x: 'max-content' }}
                            pagination={{
                                current: current,
                                pageSize: pageSize,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100', '200'],
                                onChange: (page, size) => {
                                    setCurrent(page);
                                    setPageSize(size);
                                }
                            }}
                            columns={cols}
                            rowKey="id"
                        />

                    </div>
                </div>
            </div>
            <GenerateFilePopup ref={GenerateFileRef} />
        </div>
    );
}

export default OrdersPage;


