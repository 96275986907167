import React, { useState, useEffect } from "react";
import { useUser } from "../UserContext";
import { parseDate } from "../conf/config";
import NotificationTable from "../components/NotificationTable";
import { InputNumber, Button } from "antd";

// NotificationPage component displays three tables:
// 1. Overdue Orders
// 2. Overdue Payments
// 3. Upcoming Shipments within a specified number of days
const NotificationPage = () => {
    // Access orders data from user context
    const { orders } = useUser();
    const [overDueOrders, setOverDueOrders] = useState([]); // State to store overdue orders
    const [overDuePayments, setOverDuePayments] = useState([]); // State to store overdue payments
    const [upcomingOrders, setUpcomingOrders] = useState([]); // State to store upcoming shipments
    const [days, setDays] = useState(7); // Default number of days for upcoming shipments
    const [inputDays, setInputDays] = useState(7); // Default input value for days

    // Define the table fields (columns) for each section
    const overDueOrdersFields = ["id", "customer", "order_ID", "shipping_date_planned", "status", "quantity"];
    const overDuePaymentsFields = ["id", "customer", "order_ID", "CPN", "invoice_due", "shipping_date_planned", "shipping_date_real", "status", "quantity", "invoice_date"];
    const upcomingOrdersFields = ["id", "customer", "order_ID", "shipping_date_planned", "status", "quantity"];

    // useEffect hook to update the lists whenever `orders` or `days` changes
    useEffect(() => {
        const currentDate = new Date();

        // Filter for overdue orders where shipping_date_planned < current date and status is not 'shipped' or 'paid'
        const overdueOrders = orders.filter((order) => {
            const shippingDatePlanned = parseDate(order.shipping_date_planned);
            return (
                shippingDatePlanned < currentDate &&
                order.status !== "stwxe"
            );
        });

        // Filter for overdue payments where invoice_date < current date and status is not 'paid'
        const overduePayments = orders.filter((order) => {
            const invoiceDate = parseDate(order.invoice_date);
            return (
                invoiceDate < currentDate &&
                order.status !== "paid"
            );
        });

        // Filter for upcoming orders with shipping_date_planned within `days` from the current date
        const upcomingOrders = orders.filter((order) => {
            const shippingDatePlanned = parseDate(order.shipping_date_planned);
            const daysDifference = (shippingDatePlanned - currentDate) / (1000 * 60 * 60 * 24); // Calculate difference in days
            return daysDifference >= 0 && daysDifference <= days; // Filter orders within specified day range
        }).sort((a, b) => parseDate(a.shipping_date_planned) - parseDate(b.shipping_date_planned)); // Sort by shipping date

        // Update state with filtered data
        setOverDueOrders(overdueOrders);
        setOverDuePayments(overduePayments);
        setUpcomingOrders(upcomingOrders);
    }, [orders, days]);

    // Function to update the days state when the confirm button is clicked
    const handleConfirm = () => {
        setDays(inputDays);
    };

    return (
        <div className="notification" style={{ minHeight: "100vh" }}>
            <div>
                {/* Overdue Orders Table */}
                <h3>Overdue Orders</h3>
                <NotificationTable tablefields={overDueOrdersFields} orders={overDueOrders} />

                {/* Overdue Payments Table */}
                <h3>Overdue Payments</h3>
                <NotificationTable tablefields={overDuePaymentsFields} orders={overDuePayments} />

                {/* Upcoming Shipments Table with day range selection */}
                <h3>Upcoming Shipments within {days} Days</h3>
                <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="daysInput" style={{ marginRight: '10px' }}>Select days range: </label>
                    <InputNumber
                        id="daysInput"
                        min={1} // Minimum allowed value for input
                        max={365} // Maximum allowed value for input
                        value={inputDays} // Current input value
                        onChange={value => setInputDays(value)} // Update input value on change
                        style={{ marginRight: '10px' }}
                    />
                    <Button type="primary" onClick={handleConfirm}>Confirm</Button>
                </div>
                <NotificationTable tablefields={upcomingOrdersFields} orders={upcomingOrders} />
            </div>
        </div>
    );
};

export default NotificationPage;
