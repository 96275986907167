import React, { useState } from "react";
import "./ImportPopUp.css";
import { Button, notification } from "antd";
import { BASE_URL } from "../conf/config";
import { Modal } from "antd";
import ImportConfirmPop from "./ImportConfirmPop";
import { parseWarningsAndErrors } from "../conf/config";
import { useUser } from "../UserContext";

// component for import a single order
const ImportPopUp = ({ isModalOpen, setIsModalOpen }) => {

    const { addNewLog, fetchWithAuth } = useUser();
    const [textareaValue, setTextareaValue] = useState("");

    // confirmation pop up before the import
    const [confirmation, setConfirmation] = useState(false);
    const [response, setResponse] = useState([]);
    const [errorMsg, setErrorMsg] = useState([]);
    const [fields, setFields] = useState([]);

    const handleImport = () => {
        const msg = { type: "Import", data: textareaValue };
        document.querySelectorAll('.confirm-button').forEach(button => {
            button.disabled = true;
        });

        if (!checkImport(textareaValue)) {
            notification.warning({
                message: 'Warning',
                description: 'Required fields are missing, Please check your input.',
            });
            return;
        }

        fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify(msg),
        })
            .then(res => res.json())
            .then(data => {

                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                addNewLog("import orders", parsedWarnings, parsedErrors, msg);
                console.log("parsedErrors.length", parsedErrors.length);

                if (parsedWarnings.length > 0) {
                    notification.warning({
                        message: 'Warning',
                        description: "Some warnings occurred.", parsedWarnings,
                    });
                }

                if (parsedErrors.length > 0) {
                    notification.error({
                        message: 'Error',
                        description: "An error occurred.", parsedErrors,
                    });
                } else {
                    console.log(data);

                    document.querySelectorAll('.confirm-button').forEach(button => {
                        button.disabled = false;
                    });


                    const firstLine = textareaValue.split("\n")[0];
                    const fieldsList = firstLine.split(/;|\t/);
                    setFields(fieldsList);
                    const requiredFields = ["position_ID", "order_ID", "CPN"];
                    const missingFields = requiredFields.some(field => !fieldsList.includes(field));

                    if (missingFields) {
                        notification.error({
                            message: 'Error',
                            description: 'Invalid fields detected, please check your input.',
                        })
                    }

                    setConfirmation(true);
                    setResponse(data);
                    setTextareaValue(""); // Clear the textarea after successful import

                }
            })
            .catch(error => {
                console.error('Error:', error);
                notification.error({
                    message: 'Error',
                    description: 'Failed to import data. Please try again later.',
                });
            });
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    function checkImport(input) {
        const requiredFields = ["position_ID", "order_ID", "CPN", "shipping_date_planned", "quantity", "order_type", "customer"];
        for (let i = 0; i < requiredFields.length; i++) {
            if (!input.includes(requiredFields[i])) {
                return false;
            }
        }
        return true;
    }

    return (
        <Modal
            open={isModalOpen}
            footer={null}
            title={null}
            closable={false}
            style={{
                top: '20vh',
                height: '50vh',
            }}
            width={"80vw"}>
            <div className="import-popup__content">
                <Button className="import-popup__close-button" type="primary" danger onClick={handleClose}>close</Button>
                <h2>Import Orders</h2>
                <div><b>required fields:</b> position_ID;order_ID;CPN;shipping_date_planned;quantity;order_type;customer</div>
                <div className="import-popup__textarea-container">
                    <textarea
                        className="import-popup__textarea"
                        value={textareaValue}
                        onChange={(e) => setTextareaValue(e.target.value)}
                    ></textarea>
                </div>
                <button className="import-popup__button" onClick={handleImport}>Import</button>
            </div>
            <ImportConfirmPop
                confirmation={confirmation}
                setConfirmation={setConfirmation}
                response={response}
                errorMsg={errorMsg}
                fields={fields}
            />
        </Modal >
    );
}

export default ImportPopUp;
