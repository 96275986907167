// define BASE_URL for API calls
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const BASE_URL = "https://www.innocoso.online";

export function isTokenExpired(token) {
    if (!token) return true;

    const payloadBase64 = token.split('.')[1]; // get the payload part of the token
    const decodedPayload = JSON.parse(atob(payloadBase64));
    const currentTime = Math.floor(Date.now() / 1000);

    return decodedPayload.exp < currentTime;
}


// Utility function to parse date from various formats
export const parseDate = (dateString) => {
    // if the string is empty, return null
    if (!dateString) return null;
    let day = 1; // Default day value
    let month = 0; // Default month value (0-based, so January is 0)
    let year;

    // Determine the separator (either '.' or '-')
    const separator = dateString.includes(".") ? "." : "-";
    const parts = dateString.split(separator);

    // Check the length of the parts array to determine the format
    if (parts.length === 3) {
        // Format: "DD.MM.YYYY" or "DD-MM-YYYY"
        [day, month, year] = parts;
    } else if (parts.length === 2) {
        // Format: "MM.YYYY" or "MM-YYYY"
        [month, year] = parts;
    } else if (parts.length === 1) {
        // Format: "YYYY"
        [year] = parts;
    } else {
        // Invalid format, return Invalid Date
        return new Date("Invalid Date");
    }

    // Convert string parts to integers
    day = parseInt(day, 10);
    month = parseInt(month, 10) - 1; // Months are 0-based in JavaScript Date
    year = parseInt(year, 10);

    // Handle default values for day and month
    if (isNaN(day)) day = 1; // Default to the 1st of the month
    if (isNaN(month)) month = 0; // Default to January (0-based)

    // Create a new Date object using the year, month, and day
    return new Date(year, month, day);
};


const parseWarnings = (warnings) => {
    // warnings is an array of objects with the following structure:
    // { <order_id>: <warning_message> }
    // Parse the warnings and return a string with the formatted warnings
    if (typeof warnings === "string") {
        return warnings;
    }
    let parsedWarnings = "";

    for (const warning of warnings) {
        console.log("warning!!:", warning);
        const keys = Object.keys(warning);
        for (const key of keys) {
            // concatinate each warning message
            if (warning[key].length > 0 && warning[key] !== false) {
                let currentWarning = `Order id ${key}:${warning[key].join("\n")}\n`;
                parsedWarnings += currentWarning;
            }

        }
    }
    return parsedWarnings.trim();
}

const parseErrors = (errors) => {
    // errors is an array of objects with the following structure:
    // { <order_id>: <error_message> }
    // Parse the errors and return a string with the formatted errors

    // if type of errors is string return it
    if (typeof errors === "string") {
        return errors;
    }
    let parsedErrors = "";
    for (const error of errors) {

        const keys = Object.keys(error);
        for (const key of keys) {
            if (error[key].length > 0 && error[key] !== false)
                parsedErrors += `Order id ${key}:${error[key].join("\n")}\n`;
        }
    }
    return parsedErrors.trim();
}

// use it everytime when receiving data from backend
export const parseWarningsAndErrors = (data) => {
    const errors = data["errors"];
    const warnings = data["warnings"];
    let parsedWarnings = "";
    let parsedErrors = "";
    if ((warnings)) {
        parsedWarnings = parseWarnings(warnings);
    }

    if ((errors)) {
        parsedErrors = parseErrors(errors);
    }
    return { parsedWarnings, parsedErrors };
}


// helper function to set cookie
export function setCookie(name, value, hours) {
    let expires = "";
    if (hours) {
        const date = new Date();
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/;";
}


// helper function to get cookie
export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}


export function deleteCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999; path=/';
}



