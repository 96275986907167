import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { useUser } from '../UserContext';

const HeaderMenu = () => {
    const location = useLocation();
    const pathname = location.pathname;
    // define the header options based on user group
    const { userGroups } = useUser();

    return (
        <Menu theme="dark" mode="horizontal" selectedKeys={[pathname]} style={{ width: '100%' }}>
            {userGroups.includes('visitor') ?
                (
                    // visitor
                    <>
                        <Menu.Item key="/home"><Link to="/home">Home</Link></Menu.Item>
                        <Menu.Item key="/orders"><Link to="/orders">Orders</Link></Menu.Item>
                    </>
                ) : (
                    // admin
                    <>
                        <Menu.Item key="/home"><Link to="/home">Home</Link></Menu.Item>
                        <Menu.Item key="/orders"><Link to="/orders">Orders</Link></Menu.Item>
                        <Menu.Item key="/notifications"><Link to="/notifications">Notifications</Link></Menu.Item>
                        <Menu.Item key="/log"><Link to="/log">Logs</Link></Menu.Item>
                    </>
                )}

        </Menu>
    );
};

export default HeaderMenu;
