import React, { useState, useEffect } from "react";
import { Table, Select, Button } from 'antd';
import { PushpinOutlined, PushpinFilled, DeleteOutlined, DeleteFilled } from '@ant-design/icons';
import { parseDate } from "../conf/config";
import "./OrderTable.css";
import { OrderContext } from "../OrdersContext";
import { useContext } from "react";

const OrderTable = ({ orders, setFilteredOrders, selectedRowKeys, setSelectedRowKeys, setOrdersSelectedByUser }) => {
    const [pinOrders, setPinOrders] = useState([]); // State for pinned orders
    const [cols, setCols] = useState([]); // State for table columns
    const [options, setOptions] = useState([]); // State for column options
    const [colFields, setColFields] = useState([]); // State for column fields
    const [current, setCurrent] = useState(1); // State for current pagination page
    const [pageSize, setPageSize] = useState(20); // State for page size
    const [showSelectedOnly, setShowSelectedOnly] = useState(true); // State to toggle selected only view

    const { fieldMapping } = useContext(OrderContext); // Use context for shared state

    const defaultColumnFields = [
        "id", "status", "shipping_date_planned", "total_net", "customer", "order_type",
        "position_ID", "CPN", "order_date",
        "shipping_ID", "quantity"
    ];


    useEffect(() => {
        const initialPinnedOrders = orders.filter(order => selectedRowKeys.includes(order.id));
        setPinOrders(initialPinnedOrders);
    }, [orders, selectedRowKeys]);

    useEffect(() => {
        if (orders.length > 0) {
            const firstRecord = orders[0];
            if (typeof firstRecord !== 'object') {
                throw new TypeError('not support response type');
            } else {
                setColFields(defaultColumnFields.map(item => ({
                    label: fieldMapping[item] || item,
                    value: item
                })));

                const options = Object.keys(firstRecord).map(item => ({
                    label: fieldMapping[item] || item,
                    value: item,
                }));

                setOptions(options);
            }
        }
    }, [orders]);

    useEffect(() => {
        if (colFields.length > 0) {
            const cols = [
                {
                    title: '',
                    render: (_, record) => (
                        pinOrders.some(order => order.id === record.id) ? (
                            <PushpinFilled onClick={() => handlePinClick(record)} />
                        ) : (
                            <PushpinOutlined onClick={() => handlePinClick(record)} />
                        )
                    ),
                    align: 'center',
                },
                ...colFields.map((field, index) => ({
                    title: () => (
                        <div className="table-header-column">
                            <Select
                                options={sortColFieldsAlphabetically(options)}
                                value={field.value}
                                dropdownStyle={{ width: 150 }}
                                onClick={(e) => e.stopPropagation()} // Prevent it from triggering the table row click event
                                onChange={(value) => handleColFieldChange(value, index)}
                            />
                            <div
                                className="delete-icon-wrapper"
                                onClick={() => handleColFieldDelete(field)}
                            >
                                <DeleteOutlined className="delete-icon" />
                                <DeleteFilled className="delete-icon filled" />
                            </div>
                        </div>
                    ),
                    dataIndex: field.value,
                    key: field.value,
                    sorter: (a, b, sortOrder) => getSorter(field.value)(a, b, sortOrder),
                    sortDirections: ['ascend', 'descend'],
                    render: (text) => (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {text}
                        </div>
                    ),
                    align: 'center',
                })).filter(col => col.dataIndex)
            ];
            setCols(cols);
        }
    }, [colFields, pinOrders]);

    // Handle column field change
    const handleColFieldChange = (value, index) => {
        const newColFields = [...colFields];
        newColFields[index] = { ...newColFields[index], value, label: value };
        setColFields(newColFields);
    };

    // Handle column field deletion
    const handleColFieldDelete = (field) => {
        const newColFields = colFields.filter(item => item.value !== field.value);
        setColFields(newColFields);
    };

    // Handle pin icon click
    const handlePinClick = (record) => {
        let newPinOrders = [];
        const exist = pinOrders.some(order => order.id === record.id);
        if (exist) {
            newPinOrders = pinOrders.filter(order => order.id !== record.id);
        } else {
            record["isTop"] = true;
            newPinOrders = [...pinOrders, record];
        }
        setPinOrders(newPinOrders);
    };

    // Sorting logic for columns
    const getSorter = (field) => (a, b, sortOrder) => {
        const isDateField = field.toLowerCase().includes("date");
        if (sortOrder === 'ascend') {
            if (a.isTop !== b.isTop) {
                return a.isTop ? -1 : 1;
            }
        } else if (sortOrder === 'descend') {
            if (a.isTop !== b.isTop) {
                return a.isTop ? 1 : -1;
            }
        }
        if (isDateField) {
            const dateA = parseDate(a[field]);
            const dateB = parseDate(b[field]);
            return dateA - dateB;
        }
        if (typeof a[field] === 'number' && typeof b[field] === 'number') {
            return a[field] - b[field];
        }
        return a[field].localeCompare(b[field]);
    };

    // Sort column fields alphabetically
    function sortColFieldsAlphabetically(colFields) {
        const newColFields = [...colFields];
        newColFields.sort((a, b) => {
            let strA = String(a.label).toLowerCase();
            let strB = String(b.label).toLowerCase();
            return strA.localeCompare(strB);
        });
        return newColFields;
    }

    // Handle row selection change
    const onSelectChange = (newSelectedRowKeys) => {
        //debug
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);

        setSelectedRowKeys(newSelectedRowKeys); // Update selectedRowKeys with new selection
        // filter orders and set
        // const selectedOrders = orders.filter(order => newSelectedRowKeys.includes(order.id));
        // setFilteredOrders(selectedOrders); // This line is crucial, as it updates the parent component with filtered orders.
    };

    // Pin or unpin all selected rows
    const togglePinSelected = () => {
        const newPinOrders = showSelectedOnly
            ? pinOrders.filter(order => !selectedRowKeys.includes(order.id)) // Unpin selected
            : [...pinOrders, ...orders.filter(order => selectedRowKeys.includes(order.id))]; // Pin selected
        setPinOrders(newPinOrders);
        setShowSelectedOnly(!showSelectedOnly); // Toggle between Pin and Unpin
    };

    // Table row selection configuration
    const rowSelection = {
        selectedRowKeys, // Pass selected row keys to table
        onChange: onSelectChange, // Handle row selection change
    };

    // Handle table pagination changes
    const handleTableChange = (pagination) => {
        setCurrent(pagination.current); // Update current page
        setPageSize(pagination.pageSize); // Update page size
    };

    // Generate table data source with pinned orders at the top
    const TableDataSource = () => {
        let dataSource = [];
        const data = orders;

        const pinOrdersArray = Array.isArray(pinOrders) ? pinOrders : [];
        const filteredData = data.filter(order => !pinOrdersArray.some(pinned => pinned.id === order.id));
        dataSource = [...pinOrdersArray, ...filteredData];
        return dataSource;
    };

    // Add a new column field
    const addColField = () => {
        const availableOptions = options.filter(item => !colFields.some(field => field.label === item.label));
        if (availableOptions.length > 0) {
            setColFields([...colFields, availableOptions[0]]);
        }
    };

    return (
        <div className="main-container">
            <div className="table-control-buttons">
                <Button onClick={addColField} type="primary">Add Table Column</Button>
                <Button onClick={togglePinSelected} style={{ marginLeft: '10px' }}>
                    {showSelectedOnly ? 'Unpin Selected' : 'Pin Selected'}
                </Button>
            </div>
            <div className="table-content">
                <div className="table-container">
                    <Table
                        className="order-table"
                        rowSelection={rowSelection} // Apply row selection configuration
                        dataSource={TableDataSource()} // Provide table data
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            current: current,
                            pageSize: pageSize,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50', '100', '200'],
                            onChange: (page, size) => {
                                setCurrent(page);
                                setPageSize(size);
                            }
                        }}
                        columns={cols} // Set columns for the table
                        rowKey="id" // Use 'id' as the key for each row
                    />
                </div>
            </div>
        </div>
    );
};

export default OrderTable;




