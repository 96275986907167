function dateToObj(dateString) {

    const dateFormat1 = /^\d{4}-\d{2}-\d{2}$/;  // match YYYY-MM-DD
    const dateFormat2 = /^\d{2}\.\d{2}\.\d{4}$/;  // match DD.MM.YYYY

    let date;

    if (dateFormat1.test(dateString)) {
        date = new Date(dateString);  //  YYYY-MM-DD 
    } else if (dateFormat2.test(dateString)) {

        const [day, month, year] = dateString.split('.');
        date = new Date(`${year}-${month}-${day}`);
    } else {
        throw new Error('Invalid date format');
    }

    if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
    }

    return date;
}


function checkDictOfListSanity(requestData) {
    const invalidSymbols = ["'", '"', ';'];

    // for (const [key, valueList] of Object.entries(requestData)) {
    //     for (const val of valueList) {
    //         if (typeof val === 'string' && invalidSymbols.some(symbol => val.includes(symbol))) {
    //             return "Invalid symbol used somewhere. e.g. \" or ' or ;";
    //         }
    //         if (key === 'id') {
    //             if (isNaN(parseInt(val))) {
    //                 return `Invalid id ${val}`;
    //             }
    //         } else if (key === 'quantity') {
    //             if (isNaN(parseInt(val))) {
    //                 return `Invalid quantity, must be integer <${val}>`;
    //             }
    //         } else if (key.includes('date')) {
    //             try {
    //                 dateToObj(val);
    //             } catch (error) {
    //                 return `Invalid date <${val}>`;
    //             }
    //         }
    //     }
    // }
    return 'sane';
}


function checkListOfDictSanity(requestData) {
    const invalidSymbols = ["'", '"', ';'];

    // for (const orderDict of requestData) {
    //     for (const [key, val] of Object.entries(orderDict)) {
    //         if (typeof val === 'string' && invalidSymbols.some(symbol => val.includes(symbol))) {
    //             return "Invalid symbol used somewhere. e.g. \" or ' or ;";
    //         }
    //         if (key === 'id') {
    //             if (isNaN(parseInt(val))) {
    //                 return `Invalid id ${val}`;
    //             }
    //         } else if (key === 'quantity') {
    //             if (isNaN(parseInt(val))) {
    //                 return `Invalid quantity, must be integer <${val}>`;
    //             }
    //         } else if (key.includes('date')) {
    //             try {
    //                 dateToObj(val);
    //             } catch (error) {
    //                 return `Invalid date <${val}>`;
    //             }
    //         }
    //     }
    // }
    return 'sane';
}


export {
    dateToObj,
    checkDictOfListSanity,
    checkListOfDictSanity
}