import React, { useState } from "react";
import { Collapse } from "antd";
import "./NotificationTable.css";

const { Panel } = Collapse;

const NotificationTable = ({ tablefields, orders }) => {
    const [activeKey, setActiveKey] = useState(['0']); // panel key to be expanded by default

    const handleCollapseChange = (key) => {
        setActiveKey(key);
    };

    return (
        <div>
            <Collapse activeKey={activeKey} onChange={handleCollapseChange}>
                <Panel
                    header={`View ${orders.length} orders`}
                    key="1"
                    style={{
                        maxHeight: activeKey.includes("1") ? "none" : "150px",
                        overflow: "hidden"
                    }}
                >
                    <table className="notification-table" style={{ width: '100%', borderCollapse: 'separate' }}>
                        <thead>
                            <tr>
                                {tablefields.map((field, index) => (
                                    <th key={index}>
                                        {field}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order, rowIndex) => (
                                <tr key={rowIndex}>
                                    {tablefields.map((field, colIndex) => (
                                        <td key={colIndex}>
                                            {order[field] ? order[field] : ""}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Panel>
            </Collapse>
        </div>
    );
};

export default NotificationTable;
