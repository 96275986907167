import React from 'react';
import { useUser } from '../UserContext';
import { Timeline, Card, Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

const LogsPage = () => {
    const { logs } = useUser(); // Access logs from the UserContext

    // Reverse the logs array to show the most recent logs first
    const sortedLogs = [...logs].reverse();

    return (
        <div style={{ padding: '20px', backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
            <Title level={2}>Logs</Title>
            <Timeline>
                {/* Display message if there are no logs */}
                {sortedLogs.length === 0 ? (
                    <Timeline.Item>
                        <Text type="secondary">No logs available</Text>
                    </Timeline.Item>
                ) : (
                    // Iterate through sorted logs and render each as a Timeline.Item
                    sortedLogs.map((log, index) => (
                        <Timeline.Item key={index}>
                            <Card
                                title={new Date(log.time).toLocaleString()} // Display log timestamp in a readable format
                                bordered={true} // Add a border around the card
                                shadow={true} // Shadow effect for the card
                            >
                                {/* Display the action name */}
                                <Title level={3} style={{ marginTop: '0px' }}>Action: {log.action}</Title>

                                {/* Render orders if they exist */}
                                {log.orders && log.orders.length > 0 ? (
                                    <Paragraph>
                                        <Text strong>Orders:</Text>
                                        <pre style={{ whiteSpace: 'pre-wrap', color: '#1890ff' }}>
                                            {log.orders} {/* Display the orders string */}
                                        </pre>
                                    </Paragraph>
                                ) : (
                                    // Show a fallback message if no orders are available
                                    <Paragraph>
                                        <Text type="secondary">No orders available.</Text>
                                    </Paragraph>
                                )}

                                {/* Render warnings if they exist */}
                                {log.warnings && log.warnings.length > 0 && (
                                    <Paragraph>
                                        <Text type="warning" strong>Warnings:</Text>
                                        <pre style={{ whiteSpace: 'pre-wrap', color: '#faad14' }}>
                                            {log.warnings} {/* Display the warnings */}
                                        </pre>
                                    </Paragraph>
                                )}

                                {/* Render errors if they exist */}
                                {log.errors && log.errors.length > 0 && (
                                    <Paragraph>
                                        <Text type="danger" strong>Errors:</Text>
                                        <pre style={{ whiteSpace: 'pre-wrap', color: '#ff4d4f' }}>
                                            {log.errors} {/* Display the errors */}
                                        </pre>
                                    </Paragraph>
                                )}

                                {/* Show a message if there are no warnings or errors */}
                                {log.warnings.length === 0 && log.errors.length === 0 && (
                                    <Paragraph>
                                        <Text type="secondary">No warnings or errors.</Text>
                                    </Paragraph>
                                )}
                            </Card>
                        </Timeline.Item>
                    ))
                )}
            </Timeline>
        </div>
    );
};

export default LogsPage;
