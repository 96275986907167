import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Layout, Button, notification } from 'antd';
import HomePage from './pages/Home';
import OrdersPage from './pages/Orders';
import NotificationPage from './pages/Notification';
import LogPage from './pages/Log';
import LoginPage from './pages/Login';
import HeaderMenu from './components/HeaderMenu';
import ImportPopUp from './components/ImportPopUp';
import { useUser } from './UserContext';
import VisitorOrdersPage from './pages/VisitorOrder';
import { isTokenExpired } from './conf/config';
import AddOrderPop from './components/AddOrdersPop';
import { getCookie, setCookie, deleteCookie } from './conf/config';
import { OrderProvider } from './OrdersContext';
import { BASE_URL } from './conf/config';
import FileUploadPop from './components/FileUploadPop';

const { Header, Content } = Layout;

function App() {
  // State for managing the import modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  // State to track if the user is authenticated
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // Retrieve user information from UserContext
  const { userGroups, orders, fetchWithAuth } = useUser();
  // Reference to the Add Order modal component
  const addOrderModalRef = useRef(null);
  // file for parse pdf
  const fileInputRef = useRef(null); // hide the file input

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      notification.info({
        message: "File Uploaded",
        description: `The file "${file.name}" is being parsed. Please wait...`,
      });
      handleParseDoc(file); // send parse request to backend
    }
  };

  const handleParseDoc = (file) => {
    if (!file) {
      notification.error({
        message: "Error",
        description: "Please select a file before parsing.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    fetchWithAuth(`${BASE_URL}/api/parse/`, {
      method: "POST",
      body: formData, // 
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to parse the document.");
        }
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          notification.success({
            message: "Success",
            description: `The file "${file.name}" was parsed successfully!`,
          });
          console.log("Parsed Content:", data.content); // debug
        } else {
          notification.error({
            message: "Error",
            description: data.error || "Unknown error occurred.",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notification.error({
          message: "Error",
          description: "Failed to parse the document. Please try again later.",
        });
      });
  };


  // const triggerFileUpload = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click(); // trigger the file input click event
  //   }
  // };

  // Check authentication status on initial render
  useEffect(() => {
    const token = getCookie('access_token');
    if (token && !isTokenExpired(token)) {
      // Set authenticated if token is valid
      setIsAuthenticated(true);
    } else {
      // If no valid token, clear authentication and remove tokens
      setIsAuthenticated(false);
      deleteCookie('access_token');
      deleteCookie('refresh_token');
    }
  }, []);

  // Open the import modal when "Import" button is clicked
  function handleImport() {
    setIsModalOpen(true);
  }

  // Handle user logout
  function handleLogout() {
    deleteCookie('access_token');
    deleteCookie('refresh_token');
    setIsAuthenticated(false);
  }

  // Handle adding a new order by opening Add Order modal
  function handAddOrder() {
    addOrderModalRef.current.open();
    // Retrieve keys from the first order to initialize a new order object
    const keys = Object.keys(orders[0]);
    console.log("keys:", keys);

    // Create a new empty order object
    const newOrder = keys.reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});

    // Remove the id field from the new order object
    delete newOrder.id;
    console.log("create a new order:", newOrder);
    // Set the new order to the AddOrderPop component
    addOrderModalRef.current.setInvoiceOrders([newOrder]);
  }

  // Check if current path is a media path to conditionally render components
  const isMediaPath = window.location.pathname.startsWith('/media');

  if (isMediaPath) {
    return null; // Render nothing for media paths
  }

  return (
    <Router>
      <OrderProvider>
        <Layout className="layout">
          {isAuthenticated ? (
            <>
              <Header className="layout-header">
                <HeaderMenu />
                <div className="header-buttons">
                  {userGroups.includes('admin') ? (

                    <>
                      <FileUploadPop />
                      {/* hidden input for files */}
                      {/* <input
                        type="file"
                        accept="application/pdf"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />

                      <Button type="primary" className="header-button" onClick={triggerFileUpload}>
                        Parse doc
                      </Button> */}

                      <Button type="primary" className="header-button" onClick={handleImport}>
                        Import
                      </Button>
                      <Button type="primary" className="header-button" onClick={handAddOrder}>
                        Add
                      </Button>
                      <Button type="primary" className="header-button" onClick={handleLogout} danger>
                        Logout
                      </Button>
                    </>
                  ) : (
                    <Button type="primary" className="header-button" onClick={handleLogout} danger>
                      Logout
                    </Button>
                  )}
                </div>
              </Header>
              <Content style={{ padding: '0 50px' }}>
                <div className="site-layout-content" style={{ marginTop: '20px' }}>
                  <Routes>
                    {/* Conditionally render routes based on user groups */}
                    {userGroups.includes('visitor') ? (
                      <>
                        <Route path="/" element={<OrdersPage />} />
                        <Route path="/orders" element={<VisitorOrdersPage />} />
                        <Route path="*" element={<Navigate to="/" />} />
                      </>
                    ) : (
                      <>
                        <Route path="/" element={<OrdersPage />} />
                        <Route path="/home" element={<HomePage />} />
                        <Route path="/orders" element={<OrdersPage />} />
                        <Route path="/notifications" element={<NotificationPage />} />
                        <Route path="/log" element={<LogPage />} />
                        <Route path="*" element={<Navigate to="/" />} />
                      </>
                    )}
                  </Routes>
                </div>
              </Content>
              {/* Modals for import and adding orders */}
              <ImportPopUp isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
              <AddOrderPop ref={addOrderModalRef} />
            </>
          ) : (
            // If not authenticated, render login route
            <Routes>
              <Route path="/login" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </Layout>
      </OrderProvider>
    </Router>
  );
}

export default App;
