import React, { createContext, useState, useContext, useEffect } from 'react';
import { BASE_URL } from './conf/config';
import { jwtDecode } from 'jwt-decode';
import { getCookie, setCookie, deleteCookie } from './conf/config';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    // Global orders
    const [orders, setOrders] = useState([]);
    // Visitor; Admin
    const [userGroups, setUserGroups] = useState([]);
    // Logs for the log page(only for admin)
    const [logs, setLogs] = useState(() => {
        const savedLogs = localStorage.getItem("logs");
        return savedLogs ? JSON.parse(savedLogs) : [];
    });

    // if get token
    const parseTokenAndSetGroups = () => {
        const accessToken = getCookie('access_token');
        if (accessToken) {
            try {
                const decodedToken = jwtDecode(accessToken);
                const groups = decodedToken.groups || [];
                setUserGroups(groups);
            } catch (error) {
                console.error('Failed to decode token:', error);
            }
        }
    };

    const refreshToken = async () => {
        const refresh_token = getCookie('refresh_token');

        if (!refresh_token) {
            window.location.href = '/login';  // if refresh token is not available, redirect to login page
            return null;
        }

        try {
            const response = await fetch(`${BASE_URL}/api/token/refresh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: refresh_token }),  // send refresh token to server
            });

            if (!response.ok) {
                deleteCookie('access_token');
                deleteCookie('refresh_token');
                window.location.href = '/login';
                return null;
            }

            const data = await response.json();
            setCookie('access_token', data.access, 1);  // set access token in cookie
            if (data.refresh) {
                console.log("Refresh token received: ", data.refresh);
                setCookie('refresh_token', data.refresh, 24);  // if there is a refresh token in the response, set it in cookie
            }
            return data.access;
        } catch (error) {
            console.error('Error refreshing access token:', error);
            return null;
        }
    };

    // Fetch data alawy with authentication
    const fetchWithAuth = async (url, options = {}) => {
        let access_token = getCookie('access_token');

        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${access_token}`,
        };

        try {
            let response = await fetch(url, options);

            if (response.status === 401) {
                // if access token is expired, refresh it and retry the request
                const newAccessToken = await refreshToken();

                if (newAccessToken) {
                    options.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    response = await fetch(url, options);  // retry the request with the new token
                } else {
                    throw new Error("Failed to refresh access token");
                }
            }

            return response;
        } catch (error) {
            console.error('API request failed:', error);
            throw error;
        }
    };

    useEffect(() => {
        parseTokenAndSetGroups();
    }, []);

    const processOrders = (msg) => {
        // extract orders from data
        const orders = msg["data"];
        console.log("msg", msg);
        console.log("add orders to log:", orders);
        let orderString = ""; // string to store orders
        if (orders && Array.isArray(orders) && orders.length > 0) {
            orders.forEach(order => {
                const orderID = order["order_ID"] || "Unknown";
                const positionID = order["position_ID"] || "Unknown";

                // add order details to the string
                orderString += `Order ID: ${orderID}, Position ID: ${positionID}\n`;
            });
        } else {
            console.warn("No orders found in data");
        }

        return orderString;
    };

    const addNewLog = (action, warnings = "", errors = "", msg = {}) => {
        const currentTime = new Date();
        const orders = processOrders(msg);
        console.log("New log added: ", { "time": currentTime, "action": action, "warnings": warnings, "errors": errors });
        setLogs((prevLogs) => {
            const newLogs = [...prevLogs, {
                "time": currentTime,
                "orders": orders,
                "action": action,
                "warnings": warnings || "",
                "errors": errors || ""
            }];
            localStorage.setItem("logs", JSON.stringify(newLogs));  // logs stored in local storage
            return newLogs;
        });
    };

    // execute when orders data change
    const refreshOrders = () => {
        const apiEndpoint = userGroups.includes('visitor')
            ? `${BASE_URL}/api/visitor/`
            : `${BASE_URL}/api/orders/`;

        const token = getCookie('access_token');
        if (!token) {
            window.location.href = '/login';
            return;
        }

        fetchWithAuth(apiEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (res.status === 401) {
                    deleteCookie('access_token');
                    window.location.href = '/login';
                    return;
                }
                return res.json();
            })
            .then((data) => {
                if (data && data.length > 0) {
                    setOrders(data);
                }
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        if (userGroups.length > 0) {
            refreshOrders();
        }
    }, [userGroups]);

    return (
        <UserContext.Provider value={{ orders, setOrders, refreshOrders, addNewLog, logs, userGroups, fetchWithAuth }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
