import React, { useState, useEffect, useMemo } from "react";
import { Table, Select, DatePicker, Dropdown, Menu, Button, Input, notification } from "antd";
import { useUser } from "../UserContext";
import { parseDate } from "../conf/config"; // transform date string into Date object
import { Line, Pie, Column } from "@antv/g2plot";
import { DownOutlined } from "@ant-design/icons";
import OrderTable from "../components/OrderTable";
import "./Home.css";
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;

//const seriesOptions = ["Total", "CPN", "customer", "fob_point", "incoterm", "order_type", "shipping_CO", "shipping_method", "status"]; // Define seriesOptions before using it

const HomePage = () => {
    const { orders } = useUser(); // Retrieve orders from UserContext
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); // State to hold selected row keys
    const [filteredOrders, setFilteredOrders] = useState(orders); // State to hold filtered orders based on date range
    const [dateRange, setDateRange] = useState([
        dayjs('2020-01-01', 'YYYY-MM-DD'),
        dayjs(),
    ]); // State to hold selected date range
    const [chartType, setChartType] = useState("line"); // State to hold selected chart type
    const [interval, setInterval] = useState("day"); // State to hold selected time interval

    const [yField, setYField] = useState("quantity"); // State to hold the selected yField

    const [dateField, setDateField] = useState("shipping_date_planned"); // State to hold selected date field

    const [finalSelectedOrders, setFinalSelectedOrders] = useState([]); // State to hold the final selected orders showed on plot

    const [ordersFilteredBySeries, setOrdersFilteredBySeries] = useState([]); // State to hold the orders filtered by series

    const handleDateFieldChange = (value) => {
        setDateField(value);
    };


    const numberFields = ["unit_price", "quantity", "total_net"]; // Fields to be summed

    const seriesOptions = useMemo(() => {
        let seriesOptions = ["Total"];
        if (orders.length > 0) {
            const keys = Object.keys(orders[0]);
            seriesOptions.push(...keys.filter(key => !numberFields.includes(key)));
        }
        return seriesOptions;
    }, [orders]);

    // Temporary states for multiple Add Series components
    const [series, setSeries] = useState([
        {
            name: 'Total',
            selectedSeriesOption: 'Total',
            seriesValues: []
        }
    ]);

    // monitor selectedRowKeys
    useEffect(() => {
        console.log("selectedRowKeys changed:", selectedRowKeys);

    }, [selectedRowKeys]);

    const [addSeriesComponents, setAddSeriesComponents] = useState([
        {
            key: 0,
            name: 'Total',
            selectedSeriesOption: 'Total',
            seriesValues: [],
            seriesValuesOptions: [],
            confirmed: true // Already confirmed by default
        }
    ]);



    // Filter orders based on selected date range and update selected row keys
    useEffect(() => {
        console.log("dateField changed:", dateField);
        const [start, end] = dateRange;
        console.log("start:", start, "end:", end);
        if (dateRange.length === 2) { // If a date range is selected
            const [start, end] = dateRange;
            console.log("start:", start, "end:", end);
            const filtered = orders.filter(order => {
                const shippingDate = parseDate(order[dateField]);
                return shippingDate >= start && shippingDate <= end;
            });
            setFilteredOrders(filtered);
            setSelectedRowKeys(filtered.map(order => order.id));
        } else {
            setFilteredOrders(orders);
            setSelectedRowKeys(orders.map(order => order.id));
        }
    }, [orders, dateRange, dateField]);



    // Filter orders based on selected series
    const filterOrdersBySeries = (orders, series) => {
        return series.map(condition => {
            if (condition.selectedSeriesOption === 'Total') {
                return orders; // Include all orders for 'Total'
            }
            // Ensure seriesValues and order[condition.selectedSeriesOption] are defined
            if (!condition.seriesValues || !Array.isArray(condition.seriesValues)) {
                return []; // Return an empty array if seriesValues is undefined or not an array
            }

            return orders.filter(order => {
                // Check if order[condition.selectedSeriesOption] is defined and is included in seriesValues
                return order[condition.selectedSeriesOption] && condition.seriesValues.includes(order[condition.selectedSeriesOption]);
            });
        });
    };


    const groupByDay = (orders, seriesType) => {
        const groupedData = {};

        orders.forEach(order => {
            const dateKey = order.shipping_date_planned;
            if (!groupedData[dateKey]) {
                groupedData[dateKey] = { shipping_date_planned: dateKey, series: seriesType };
                numberFields.forEach(field => {
                    groupedData[dateKey][field] = 0;
                });
            }
            numberFields.forEach(field => {
                groupedData[dateKey][field] += parseFloat(order[field]) || 0;
            });
        });

        return Object.values(groupedData);
    };

    const groupByMonth = (orders, seriesType) => {
        const groupedData = {};
        orders.forEach(order => {
            const date = parseDate(order.shipping_date_planned);
            const monthKey = `${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
            if (!groupedData[monthKey]) {
                groupedData[monthKey] = { shipping_date_planned: monthKey, series: seriesType };
                numberFields.forEach(field => {
                    groupedData[monthKey][field] = 0;
                });
            }
            numberFields.forEach(field => {
                groupedData[monthKey][field] += parseFloat(order[field]) || 0;
            });
        });
        return Object.values(groupedData).sort((a, b) => parseDate(a.shipping_date_planned) - parseDate(b.shipping_date_planned));
    };

    const groupByYear = (orders, seriesType) => {
        const groupedData = {};
        orders.forEach(order => {
            const date = parseDate(order.shipping_date_planned);
            const yearKey = `${date.getFullYear()}`;
            if (!groupedData[yearKey]) {
                groupedData[yearKey] = { shipping_date_planned: yearKey, series: seriesType };
                numberFields.forEach(field => {
                    groupedData[yearKey][field] = 0;
                });
            }
            numberFields.forEach(field => {
                groupedData[yearKey][field] += parseFloat(order[field]) || 0;
            });
        });
        return Object.values(groupedData).sort((a, b) => parseDate(a.shipping_date_planned) - parseDate(b.shipping_date_planned));
    };

    const groupOrders = (orders, interval, seriesType) => {
        if (interval === 'day') return groupByDay(orders, seriesType);
        if (interval === 'month') return groupByMonth(orders, seriesType);
        if (interval === 'year') return groupByYear(orders, seriesType);
        return [];
    };

    // Handle changes in the selected date range
    const handleDateRangeChange = (dates) => {
        if (dates) {
            setDateRange(dates.map(date => date.toDate()));
        } else {
            setDateRange([]);
        }
    };

    // Extract unique values for the selected series option
    const extractOptions = (field) => {
        if (!field || filteredOrders.length === 0) return [];
        return [...new Set(filteredOrders.map(order => order[field]))];
    };

    // Handle Add Series button click to add a new series component
    const handleAddSeriesClick = () => {
        const defaultName = `series-${addSeriesComponents.length + 1}`;
        setAddSeriesComponents(prevComponents => [
            ...prevComponents,
            {
                key: prevComponents.length,
                name: defaultName,
                selectedSeriesOption: "",
                seriesValues: [],
                seriesValuesOptions: [],
                confirmed: false
            }
        ]);
    };

    const updateSeriesComponent = (index, newValues) => {
        setAddSeriesComponents(prevComponents => {
            const updatedComponents = [...prevComponents];
            updatedComponents[index] = { ...updatedComponents[index], ...newValues };

            // Also update the series state
            const component = updatedComponents[index];
            setSeries(prevSeries => {
                const updatedSeries = [...prevSeries];
                updatedSeries[index] = {
                    name: component.name,
                    selectedSeriesOption: component.selectedSeriesOption,
                    seriesValues: component.seriesValues
                };
                return updatedSeries;
            });

            return updatedComponents;
        });
    };


    const handleConfirmSeries = (index) => {
        const component = addSeriesComponents[index];
        if (component.selectedSeriesOption && component.seriesValues.length > 0) {
            const newSeries = {
                [component.selectedSeriesOption]: component.seriesValues,
                name: component.name
            };
            setSeries(prevSeries => [...prevSeries, newSeries]);
            updateSeriesComponent(index, { confirmed: true });
        }
    };

    // Ensure that a series is selected before rendering pie chart


    function handChartTypeChange(key) {
        if (key === 'pie') {
            if (series.length === 0) {
                notification.error({
                    message: 'Error',
                    description: 'Please select at least one series for the pie chart'
                }
                );
                return;
            }
        }
        setChartType(key);
    }

    const chartTypeMenu = (
        <Menu onClick={(e) => handChartTypeChange(e.key)}>
            <Menu.Item key="line">Line Chart</Menu.Item>

            <Menu.Item key="bar">Bar Chart</Menu.Item>

            <Menu.Item key="pie">Pie Chart</Menu.Item>
        </Menu>
    );

    useEffect(() => {
        const chartContainer = document.getElementById('chart');
        if (chartContainer) {
            chartContainer.innerHTML = '';
        }

        let data = [];
        let finalOrdersId = []

        // Handle series data
        if (series.length > 0) {
            const seriesDataList = filterOrdersBySeries(filteredOrders, series);
            let ordersSeries = []
            seriesDataList.forEach((ordersList, index) => {
                const seriesType = series[index].name || `Series-${index + 1}`;
                const groupedSeriesData = groupOrders(ordersList, interval, seriesType);
                // put all selected orders in 
                finalOrdersId = [...finalOrdersId, ...ordersList.map(order => order.id)];
                data = [...data, ...groupedSeriesData];
                ordersSeries = [...ordersSeries, ...ordersList];
            });
            setOrdersFilteredBySeries(ordersSeries);

        }


        data.sort((a, b) => parseDate(a.shipping_date_planned) - parseDate(b.shipping_date_planned));
        console.log("finially show data:", data);

        setFinalSelectedOrders(finalOrdersId);
        if (interval === 'day') {
            data = data.map(item => ({
                ...item,
                shipping_date_planned: parseDate(item.shipping_date_planned)
            }));
        }

        if (chartType === 'pie') {


            // Calculate total sums for each series and their percentages
            const pieData = series.map(serie => {
                const key = Object.keys(serie)[0];
                const totalSum = filteredOrders.reduce((sum, order) => {
                    if (serie[key].includes(order[key])) {
                        return sum + (parseFloat(order[yField]) || 0);
                    }
                    return sum;
                }, 0);
                return {
                    name: serie.name,
                    value: totalSum
                };
            });

            const piePlot = new Pie("chart", {
                data: pieData,
                angleField: 'value',
                colorField: 'name',
                radius: 0.75,
                label: {
                    type: 'spider',
                    labelHeight: 30,
                    content: '{name}: {percentage}',
                },
                interactions: [{ type: 'element-active' }],
            });
            piePlot.render();
            return () => {
                piePlot.destroy();
            };
        }

        if (data.length > 0 && chartType !== 'pie') {
            // Render line chart
            if (chartType === 'line') {
                const linePlot = new Line("chart", {
                    data,
                    padding: 'auto',
                    xField: 'shipping_date_planned',
                    yField: yField,
                    seriesField: "series",
                    xAxis: {
                        type: interval === 'day' ? 'timeCat' : 'cat',
                        label: interval === 'day' ? {} : { formatter: (val) => interval === 'month' ? val.replace('-', '/') : val },
                        title: {
                            text: 'Date',
                            style: {
                                fontSize: 14,
                                fontWeight: 'bold',
                            },
                        },
                    },
                    yAxis: {
                        label: {
                            formatter: (val) => {
                                return parseInt(val).toLocaleString(); // Format the value as a number with commas
                            },
                        },
                        title: {
                            text: yField.charAt(0).toUpperCase() + yField.slice(1),
                            style: {
                                fontSize: 14,
                                fontWeight: 'bold',
                            },
                        },
                    },
                    smooth: false,
                    height: 400,
                    point: {
                        size: 5,
                        shape: 'diamond',
                    },
                    connectNulls: true,
                });
                linePlot.render();
                return () => {
                    linePlot.destroy();
                };
            }
            // Render bar chart using G2Plot Column component
            else if (chartType === 'bar') {
                const columnPlot = new Column("chart", {
                    data,
                    padding: 'auto',
                    xField: 'shipping_date_planned',
                    yField: yField,
                    seriesField: "series",
                    isGroup: true,
                    xAxis: {
                        type: interval === 'day' ? 'timeCat' : 'cat',
                        label: interval === 'day' ? {} : { formatter: (val) => interval === 'month' ? val.replace('-', '/') : val },
                        title: {
                            text: 'Date',
                            style: {
                                fontSize: 14,
                                fontWeight: 'bold',
                            },
                        },
                    },
                    yAxis: {
                        label: {
                            formatter: (val) => {
                                return parseInt(val).toLocaleString();
                            },
                        },
                        title: {
                            text: yField.charAt(0).toUpperCase() + yField.slice(1),
                            style: {
                                fontSize: 14,
                                fontWeight: 'bold',
                            },
                        },
                    },
                    height: 400,
                    columnStyle: {
                        radius: [4, 4, 0, 0],
                    },
                });
                columnPlot.render();
                return () => {
                    columnPlot.destroy();
                };
            }
        }
    }, [filteredOrders, chartType, interval, series, yField]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    const handleIntervalChange = (value) => {
        setInterval(value);
    };

    const handleYFieldChange = (value) => {
        setYField(value);
    };

    return (
        <div className="home-page">
            <div className="top-bar" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '80px' }}>
                <div className="plot-options" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 10, fontWeight: 'bold' }}>Chart Type:</span>
                    <Dropdown overlay={chartTypeMenu}>
                        <Button>
                            {chartType.charAt(0).toUpperCase() + chartType.slice(1)} Chart <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>

                <div className="date-field-selector" style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                    <label style={{ marginRight: 10, fontWeight: 'bold' }}>Date Field:</label>
                    <Select
                        value={dateField}
                        onChange={handleDateFieldChange}
                        style={{ width: 180 }}
                    >
                        <Option value="shipping_date_planned">Shipping Date Planned</Option>
                        <Option value="shipping_date_real">Shipping Date Real</Option>
                    </Select>
                </div>

                <div className="time-selector" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span style={{ marginRight: 10, fontWeight: 'bold' }}>Select Time:</span>
                    <RangePicker
                        format="YYYY-MM-DD"
                        defaultValue={[
                            dayjs('2020-01-01', 'YYYY-MM-DD'),
                            dayjs(),
                        ]}
                        onChange={handleDateRangeChange}
                    />
                </div>

                <div className="interval-selector" style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: 10, fontWeight: 'bold' }}>Interval:</label>
                    <Select
                        value={interval}
                        onChange={handleIntervalChange}
                        style={{ width: 120 }}
                    >
                        <Option value="day">Day</Option>
                        <Option value="month">Month</Option>
                        <Option value="year">Year</Option>
                    </Select>
                </div>
            </div>

            <div className="series-selector">
                <Button onClick={handleAddSeriesClick}>
                    Add Series
                </Button>

                {addSeriesComponents.map((component, index) => (
                    <div key={component.key} className="add-series-container" style={{ marginTop: 10 }}>
                        {/* Input for series name */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <label style={{ marginRight: 10, fontWeight: 'bold' }}>Series Name:</label>
                            <Input
                                value={component.name}
                                onChange={(e) => updateSeriesComponent(index, { name: e.target.value })}
                                style={{ width: 120, marginRight: 10 }}
                            />
                        </div>

                        {/* First Dropdown: Select Series Option */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <label style={{ marginRight: 10, fontWeight: 'bold' }}>Field:</label>
                            <Select
                                value={component.selectedSeriesOption}
                                onChange={(value) => {
                                    updateSeriesComponent(index, {
                                        selectedSeriesOption: value,
                                        seriesValuesOptions: extractOptions(value)
                                    });
                                    updateSeriesComponent(index, { seriesValues: [] });
                                }}
                                placeholder="Select Series Option"
                                style={{ width: 180, marginRight: 10 }}
                            >
                                {seriesOptions.map(option => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        {/* Second Dropdown: Select Series Values (Multi-select) */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <label style={{ marginRight: 10, fontWeight: 'bold' }}>Values:</label>
                            <Select
                                mode="multiple"
                                value={component.seriesValues}
                                showSearch={false}
                                onChange={(values) => updateSeriesComponent(index, { seriesValues: values })}
                                placeholder="Select Values"
                                style={{ width: 450, marginRight: 10 }}
                                disabled={component.selectedSeriesOption === 'Total'} // Disable if 'Total' is selected
                            >
                                {component.seriesValuesOptions.map(value => (
                                    <Option key={value} value={value}>
                                        {value}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        {/* Confirm Button */}
                        <Button
                            type="primary"
                            onClick={() => handleConfirmSeries(index)}
                        // Apply button is always visible
                        >
                            Apply
                        </Button>
                    </div>
                ))}


            </div>

            <div className="yfield-selector" style={{ marginTop: 20 }}>
                <label style={{ marginRight: 10, fontWeight: 'bold' }}>Y Axis Field:</label>
                <Select
                    value={yField}
                    onChange={handleYFieldChange}
                    style={{ width: 180 }}
                >
                    {numberFields.map(field => (
                        <Option key={field} value={field}>
                            {field}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="main-content" style={{ marginTop: 30 }}>
                <div className="plot-container" style={{ marginTop: 80 }}>
                    <div id="chart"></div>
                </div>
                <div className="order-container" style={{ marginTop: 20 }}>
                    <OrderTable
                        orders={filteredOrders}
                        //setOrders={setOrders}
                        setFilteredOrders={setFilteredOrders}
                        selectedRowKeys={finalSelectedOrders}
                        setSelectedRowKeys={setFinalSelectedOrders}
                    />
                </div>
            </div>
        </div>
    );
};

export default HomePage;
