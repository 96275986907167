import React, { useState } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.css';
import { BASE_URL } from '../conf/config';
import { getCookie, setCookie, deleteCookie } from '../conf/config';

// LoginPage component with login form
const LoginPage = ({ setIsAuthenticated }) => {
    // State for loading indicator and error message
    const [isLoggingIn, setIsLoggingIn] = useState(false); // Controls loading state for login button
    const [errorMessage, setErrorMessage] = useState(''); // Stores any error messages for display

    // Async function triggered upon form submission
    const onFinish = async (values) => {
        setIsLoggingIn(true); // Set loading state to true, showing button spinner

        try {
            // Send a POST request to the backend login endpoint
            const response = await fetch(`${BASE_URL}/api/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Specify JSON format for the request
                },
                body: JSON.stringify({
                    username: values.username, // Username from form input
                    password: values.password, // Password from form input
                }),
            });

            const data = await response.json(); // Parse the response data

            if (response.ok) {
                // Login successful, retrieve and store access and refresh tokens
                const { access, refresh } = data;
                setCookie('access_token', access, 1); // Save access token in cookies
                setCookie('refresh_token', refresh, 24); // Save refresh token in cookies
                setIsAuthenticated(true); // Update authentication state in parent component
                window.location.href = '/'; // Redirect to homepage
            } else {
                // If login failed, throw an error to display the message
                throw new Error(data.detail || 'Login failed');
            }
        } catch (error) {
            // Catch errors and set error message for display
            console.error('Login error:', error);
            setErrorMessage('Login failed. Please check your credentials and try again.');
            setIsLoggingIn(false); // Reset loading state
        }
    };

    return (
        <div className="login-container">
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish} // Trigger onFinish function on form submission
            >
                {/* Username input field */}
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                    <Input
                        className='login-input'
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Username"
                    />
                </Form.Item>

                {/* Password input field */}
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input
                        className='login-input'
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                {/* Display error message if present */}
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                {/* Login button, triggers form submission when clicked */}
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoggingIn}>
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginPage;
