import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, notification, Collapse } from "antd";
import { useUser } from "../UserContext";
import './ImportConfirmPop.css';
import { BASE_URL } from "../conf/config";
import { parseWarningsAndErrors } from "../conf/config";
import { OrderContext } from "../OrdersContext";

const { Panel } = Collapse;

const ImportConfirmPop = ({ confirmation, setConfirmation, response, errorMsg, fields }) => {
    const { orders, setOrders, refreshOrders, addNewLog, fetchWithAuth } = useUser();
    const { fieldMapping } = useContext(OrderContext);
    const [existingOrders, setExistingOrders] = useState([]);


    // if the fields are not provided, use the default fields, most case the showed field will read from the input
    const showedFields = fields && fields.length > 1
        ? fields
        : ["order_ID", "position_ID", "CPN", "quantity", "shipping_date_planned", "status"];

    function handleClose() {
        // update orders
        refreshOrders();
        setConfirmation(false);
    }

    useEffect(() => {
        // console.log("import response", response);
        if (!response?.DB || !orders) return;
        const identicalOrders = [];
        const newOrders = response.DB.filter((item) => {
            const matchingOrder = orders.find(order => order.id === item.id);
            if (matchingOrder) {
                // Check if all non-empty fields are identical
                const isIdentical = showedFields.every(field => {
                    // If both fields are not empty and equal, return true, otherwise false
                    return (
                        (matchingOrder[field] !== undefined && matchingOrder[field] !== '' &&
                            item[field] !== undefined && item[field] !== '') ?
                            matchingOrder[field] === item[field] : true
                    );
                });
                if (isIdentical) {
                    identicalOrders.push(item);
                    return false;
                }
            }
            return true;
        });

        console.log("orders in userUser", orders);
        // console.log("identical orders", identicalOrders);
        setExistingOrders(identicalOrders);
    }, [response.DB, orders, showedFields]);




    // should return null at first
    if (!response || !Array.isArray(response.DB) || !Array.isArray(response.not_found)) {
        return null;
    }

    const isExistingOrder = (matchingOrder, item) => {
        return showedFields.every(field => matchingOrder && matchingOrder[field] === item[field]);
    };

    const handleEdit = (item, actionType, buttonId) => {
        const data = [item];
        console.log("sending edit data:", data);
        const msg = { type: actionType, data: data };

        fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify(msg),
        })
            .then(res => res.json())
            .then(data => {
                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                addNewLog("confirm import", parsedWarnings, parsedErrors, msg);
                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors })
                    refreshOrders();
                    return;
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings })
                }

                console.log("Edit response:", data);

                notification.success({
                    message: 'Success',
                    description: 'Data edited successfully!',
                });
                const button = document.getElementById(buttonId);
                button.disabled = true;

            })
            .catch(error => {
                console.error('Error:', error);
                notification.error({
                    message: 'Error',
                    description: 'Failed to edit data. Please try again later.',
                });
            });
    };


    const handleSendAll = () => {
        const editMsg = { type: "edit", data: response.DB };
        const addMsg = { type: "add", data: response.not_found };

        const editRequest = fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify(editMsg),
        })
            .then(res => res.json())
            .then(data => {
                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                addNewLog("Yes all edit", parsedWarnings, parsedErrors, editMsg);
                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors })
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings })
                }
            });

        const addRequest = fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify(addMsg),
        })
            .then(res => res.json())
            .then(data => {
                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                addNewLog("Yes all add ", parsedWarnings, parsedErrors, addMsg);
                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors })
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings })
                }
            }
            );
        // make sure that the requests are completed before closing the modal
        Promise.all([editRequest, addRequest])
            .then(results => {
                notification.success({
                    message: 'Success',
                    description: 'All data processed successfully!',
                });
                document.querySelectorAll('.confirm-button').forEach(button => {
                    button.disabled = true;
                });
            })
            .catch(error => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to process all data. Please check and try again.',
                });
            });
    };

    return (
        <Modal
            className="import-confirmation-pop"
            open={confirmation}
            footer={null}
            title={null}
            closable={false}
            width={"80vw"}
            height={"70vh"}
        >
            <Button
                className="import-popup__close-button"
                type="primary"
                danger
                onClick={handleClose}
            >
                Close
            </Button>
            <div className="import-confirmation-table-container">
                {orders.length > existingOrders.length && (
                    <table className="import-confirmation-table">
                        <thead>
                            <tr>
                                <th colSpan={showedFields.length}>Old</th>
                                <th className="separator"></th>
                                <th colSpan={showedFields.length + 1}>New</th>
                            </tr>
                            <tr>
                                {showedFields.map((field, index) => (
                                    <th key={`old-header-${index}`}>{fieldMapping[field] || field}</th>
                                ))}
                                <th className="separator"></th>
                                {showedFields.map((field, index) => (
                                    <th key={`new-header-${index}`}>{fieldMapping[field] || field}</th>
                                ))}
                                <th>
                                    <Button type="primary" onClick={handleSendAll}>Yes All</Button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {response.DB.map((item, index) => {
                                const matchingOrder = orders.find(order => order.id === item.id);
                                const buttonId = `button-edit-${index}`;

                                if (isExistingOrder(matchingOrder, item)) {
                                    return null;
                                }

                                return (
                                    <tr key={index}>
                                        {showedFields.map((field, subIndex) => (
                                            <td key={`old-db-${index}-${subIndex}`}>
                                                {matchingOrder ? matchingOrder[field] : '-'}
                                            </td>
                                        ))}
                                        <td className="separator"></td>
                                        {showedFields.map((field, subIndex) => {
                                            const isDifferent = matchingOrder && matchingOrder[field] !== item[field];
                                            return (
                                                <td
                                                    style={{ color: isDifferent ? 'red' : 'inherit' }}
                                                    key={`new-db-${index}-${subIndex}`}
                                                >
                                                    {item[field]}
                                                </td>
                                            );
                                        })}
                                        <td>
                                            <Button
                                                id={buttonId}
                                                type="primary"
                                                className="confirm-button"
                                                onClick={() => handleEdit(item, "edit", buttonId)}
                                            >
                                                Yes
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                            {response.not_found.map((item, index) => {
                                const buttonId = `button-add-${index}`;
                                return (
                                    <tr key={index}>
                                        {showedFields.map((field, subIndex) => (
                                            <td key={`old-not-found-${index}-${subIndex}`}>-</td>
                                        ))}
                                        <td className="separator"></td>
                                        {showedFields.map((field, subIndex) => (
                                            <td style={{ color: "green" }} key={`new-not-found-${index}-${subIndex}`}>{item[field]}</td>
                                        ))}
                                        <td>
                                            <Button
                                                id={buttonId}
                                                type="primary"
                                                className="confirm-button"
                                                onClick={() => handleEdit(item, "add", buttonId)}
                                            >
                                                Yes
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {existingOrders.length > 0 && (
                    <Collapse>
                        <Panel header={`View ${existingOrders.length} identical orders`} key="1">
                            <table className="import-confirmation-table">
                                <thead>
                                    <tr>
                                        {showedFields.map((field, index) => (
                                            <th key={`existing-header-${index}`}>{field}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {existingOrders.map((item, index) => (
                                        <tr key={`existing-${index}`}>
                                            {showedFields.map((field, subIndex) => (
                                                <td key={`existing-db-${index}-${subIndex}`}>
                                                    {item[field]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Panel>
                    </Collapse>
                )}
            </div>
        </Modal>
    );
};

export default ImportConfirmPop;
