import React, { useState, useEffect, useRef, useContext } from "react";
import { BASE_URL, parseWarningsAndErrors } from "../conf/config";
import { Button, Form, Input, Select, Space, Table, Row, Col, notification, Dropdown, Menu } from 'antd';
import { PushpinOutlined, PushpinFilled, DeleteOutlined, DeleteFilled } from '@ant-design/icons';
import GenerateFilePopup from "../components/GenerateFilePopup";
import { useUser } from "../UserContext";
import { checkDictOfListSanity } from "../utils/utils";
import "./Orders.css";
import { OrderContext } from "../OrdersContext";


const VisitorOrdersPage = () => {
    const { orders, setOrders, refreshOrders, addNewLog, fetchWithAuth } = useUser();
    const {
        pinOrders, setPinOrders, searchedResult, setSearchedResult, cols, setCols,
        options, setOptions, tableSelectOptions, selectedRowKeys, setSelectedRowKeys,
        searchFields, setSearchFields, colFields, setColFields,
        current, setCurrent, pageSize, setPageSize, dropDownSearchItem, setDropDownSearchItem,
        statusOptions, setupData, handlePinClick, getSorter,
        GenerateFileRef
    } = useContext(OrderContext);

    const [form] = Form.useForm();


    const handleTableChange = (pagination) => {
        setCurrent(pagination.current); // update current page
        setPageSize(pagination.pageSize);
    };

    const defaultSearchItem = ["id", "status", "customer"];
    const defaultColumnFields = ['id', 'customer', 'invoice_date', 'invoice_due', 'shipping_date_planned', 'shipping_date_real', 'status'];

    // const [dropDownSearchItem, setDropDownSearchItem] = useState([]);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };



    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                // if select all clicked. choose all orders
                const allOrderIds = orders.map(order => order.id);
                console.log(allOrderIds);
                setSelectedRowKeys(allOrderIds);
            } else {
                setSelectedRowKeys([]);
            }
        }
    };

    const hasSelected = selectedRowKeys.length > 0;


    function sortColFieldsAlphabetically(colFields) {
        const newColFields = [...colFields];

        newColFields.sort((a, b) => {
            let strA = String(a.label).toLowerCase();
            let strB = String(b.label).toLowerCase();

            return strA.localeCompare(strB);
        });

        return newColFields;
    }

    const handleColFieldDelete = (field) => {
        const newColFields = colFields.filter(item => item.value !== field.value);
        setColFields(newColFields);
    }



    useEffect(() => {
        if (colFields.length > 0) {
            const cols = [
                {
                    title: '',
                    render: (_, record) => (
                        pinOrders.some(order => order.id === record.id) ? (
                            <PushpinFilled onClick={() => handlePinClick(record)} />
                        ) : (
                            <PushpinOutlined onClick={() => handlePinClick(record)} />
                        )
                    ),
                    align: 'center',
                },
                ...colFields.map((field, index) => ({
                    title: () => (
                        <div className="table-header-column">

                            <Select
                                // options={getFilteredColItems()}
                                dropdownStyle={{ width: 150 }}
                                options={sortColFieldsAlphabetically(options)}
                                value={field.value}
                                variant={false}
                                onClick={(e) => e.stopPropagation()}  // prevent it trigger the table row click event
                                onChange={(value) => handleColFieldChange(value, index)}
                            />
                            <div
                                className="delete-icon-wrapper"
                                onClick={() => handleColFieldDelete(field)}
                            >
                                <DeleteOutlined className="delete-icon" />
                                <DeleteFilled className="delete-icon filled" />
                            </div>
                        </div>
                    ),
                    dataIndex: field.value,
                    key: field.value,
                    sorter: (a, b, sortOrder) => getSorter(field.value)(a, b, sortOrder),
                    sortDirections: ['ascend', 'descend'],
                    render: (text) => (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {text}
                        </div>
                    ),
                    align: 'center', // center align the column
                })).filter(col => col.dataIndex)
            ];
            setCols(cols);
        }
    }, [colFields, pinOrders]);


    const addSearchField = () => {
        setSearchFields([...searchFields, getFilteredDropdownItems()[0]]);
    };

    const addColField = () => { setColFields([...colFields, getFilteredColItems()[0]]); };

    const handleFieldChange = (value, index) => {
        const newSearchFields = [...searchFields];
        newSearchFields[index] = { ...newSearchFields[index], value, label: value };
        // console.log("newSearchFields", newSearchFields);
        setSearchFields(newSearchFields);
    };

    const handleColFieldChange = (value, index) => {
        const newColFields = [...colFields];
        newColFields[index] = { ...newColFields[index], value, label: value };
        setColFields(newColFields);

    }


    const getFilteredDropdownItems = () => {
        return dropDownSearchItem.filter(item => !searchFields.some(field => field.label === item.label));

    };

    const getFilteredColItems = () => {
        return dropDownSearchItem.filter(item => !colFields.some(field => field.label === item.label));
    };

    const filteredColumns = (values) => {
        const filteredValues = Object.keys(values).reduce((acc, key) => {
            if (values[key] !== undefined && values[key] !== null && values[key] !== '') {
                const value_list = values[key].trim().split(";")
                    .map(value => value.trim())
                    .filter(value => value !== "");
                acc[key] = value_list;
            }
            return acc;
        }, {});

        return filteredValues;
    }

    const onOrderSearch = (values) => {

        const filteredValues = filteredColumns(values);
        const checkSanity = checkDictOfListSanity(filteredValues);
        if (checkSanity !== "sane") {
            notification.warning({ message: 'warning', description: checkSanity })
            return;
        }
        const msg = { "type": "search", "data": filteredValues };

        const token = localStorage.getItem('access_token');

        fetchWithAuth(`${BASE_URL}/api/visitor/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(msg)
        }).then((res) => {
            if (res.status === 401) {
                // unauthorized
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                notification.error({ message: 'Error', description: 'Unauthorized, Please login' })
                window.location.href = '/login';
                return;
            }
            return res.json();
        })
            .then((data) => {
                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);

                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors })
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings })
                }
                if (data.length === 0) {
                    notification.info({ message: 'Info', description: `No search results found` });
                }
                setSearchedResult(data)
            }).catch(err => console.log(err))
    }



    const resetSearch = () => {
        form.resetFields();
        const firstRecord = orders[0];
        if (typeof firstRecord !== 'object') {
            throw new TypeError('not support response type');
        } else {

            const searchItems = Object.keys(firstRecord).map((key, index) => ({
                label: key,
                key: index,
                value: key
            }));

            setDropDownSearchItem(searchItems);
            setSearchFields(defaultSearchItem.map(item => ({
                label: item,
                value: item
            })));
            setColFields(defaultColumnFields.map(item => ({
                label: item,
                value: item
            })))

            setSearchedResult([]);

        }
    }


    const TableDataSource = () => {
        let dataSource = [];
        const data = Array.isArray(searchedResult) && searchedResult.length > 0 ? searchedResult : orders;
        const pinOrdersArray = Array.isArray(pinOrders) ? pinOrders : [];

        // filter out those orders already exist in pinOrders
        const filteredData = data.filter(order => !pinOrdersArray.some(pinned => pinned.id === order.id));
        dataSource = [...pinOrdersArray, ...filteredData];
        return dataSource;
    }





    return (
        <div>
            <div className="form-container">
                <Form form={form} onFinish={onOrderSearch} layout="horizontal" style={{ maxWidth: 'none' }}>
                    <div className="search-form">
                        <Button className="search-button-group" type="primary" htmlType="submit">Search</Button>
                        <Button className="search-button-group" type="primary" onClick={resetSearch} danger>reset</Button>
                        <div className="search-field-group">
                            {searchFields.map((field, index) => (
                                <div key={index} className="search-field-container">
                                    <div className="search-form-item">
                                        {/* <Form.Item name={field.value} style={{ marginBottom: "0px" }}> */}
                                        <Select
                                            options={sortColFieldsAlphabetically(getFilteredDropdownItems())}
                                            onChange={(value) => handleFieldChange(value, index)}
                                            value={field.value}
                                            className="search-select custom-dropdown"
                                            dropdownStyle={{ minWidth: '100px' }}
                                            variant={false}
                                            placeholder="Select Field"
                                        />
                                        {/* </Form.Item> */}
                                        <Form.Item name={`${field.value}`} noStyle>
                                            <Input className="search-input" />
                                        </Form.Item>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Button className="search-button-group" type="dashed" onClick={addSearchField}>Add Field</Button>
                    </div>
                </Form>
            </div>


            <div className="main-container">
                <div className="table-control-buttons">
                    <Button onClick={addColField} type="primary" >Add Table Column</Button>
                </div>
                <div className="table-content">

                    <div className="table-container">
                        <Table
                            className="order-table"
                            rowSelection={rowSelection}
                            dataSource={TableDataSource()}
                            scroll={{ x: 'max-content' }}
                            pagination={{
                                current: current,
                                pageSize: pageSize,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100', '200'],
                                onChange: (page, size) => {
                                    setCurrent(page);
                                    setPageSize(size);
                                }
                            }}
                            columns={cols}
                            rowKey="id"
                        />

                    </div>
                </div>
            </div>
            <GenerateFilePopup ref={GenerateFileRef} />
        </div>
    );
}

export default VisitorOrdersPage;


